import {makeStyles} from "@material-ui/core/styles";
import {
    Alert,
    Avatar, BottomNavigation, BottomNavigationAction, Button, Card,
    CardContent,
    CardHeader, CircularProgress,
    Divider, Fab, Switch,
    Tooltip, Typography
} from "@material-ui/core";
import PropTypes from 'prop-types';
import React from "react";
import CheckIcon from '@material-ui/icons/Check';
import {green, red, teal} from "@material-ui/core/colors";
import clsx from "clsx";
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import SettingsIcon from "@material-ui/icons/Settings";
import HistoryIcon from '@material-ui/icons/History';
import InfoIcon from '@material-ui/icons/Info';
import nabEngineClient from "../libs/nabEngineClient";
import EngineSettings from "../views/EngineSettings";

const useStyles = makeStyles((theme) => ({
    card: {
        // minWidth: 300
    },
    box: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    connected: {
        paddingLeft: 13,
        paddingRight: 13
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        backgroundColor: red[600],
        '&:hover': {
            backgroundColor: red[800],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    avatar: {
        backgroundColor: teal[800],
    }
}));

const Engine = (props) => {
    const classes = useStyles();
    const [connecting, setConnecting] = React.useState(false);
    const [connected, setConnected] = React.useState(false);
    const [connectionClosed, setConnectionClosed] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [active, setActive] = React.useState(true);
    const [showSettings, setShowSettings] = React.useState(false);
    const [nEc, setNec] = React.useState(null);

    React.useEffect(() => {
        const nEc = new nabEngineClient(props.data.ip, props.data.port, props.data.protocol);

        // webSocket to NAB engine
        nEc.onError = () => {
            setConnected(false);
            setConnecting(false);
            setError(true);
            console.log('ERROR')
        }
        nEc.onOpen = () => {
            setConnected(true);
            setConnecting(false);
            setError(false);
            setConnectionClosed(false);
            console.log('OPEN')
        }
        nEc.onClose = (evt) => {
            setConnected(false);
            setConnecting(false);
            setConnectionClosed(evt.wasClean ? false : true);
            console.log('CLOSED')
        }

        setNec(nEc);
    },[props.data]);

    const buttonClassname = clsx({
        [classes.buttonError]: error,
        [classes.buttonSuccess]: connected,
    });

    const handleActive = () => {
        setConnected(false);
        setConnecting(false);
        setError(false);
        setConnectionClosed(false);

        if (active) {
            setActive(false);
            nEc.disconnect();
        } else {
            setActive(true);
        }
    }

    const handleButtonClick = () => {
        if (!connecting) {
            setConnected(false);
            setConnecting(true);

            if (!error) {
                nEc.connect();
            } else {
                // timeout to improve UI experience - I know sounds crazy ;)
                // mainly to display indicator that we retrying
                setTimeout(() => {nEc.connect()}, 1500);
            }
        }
    };

    const handleSettingsClose = () => {
        setShowSettings(false);
    }

    return (
        <React.Fragment>
            <EngineSettings open={showSettings} onClose={handleSettingsClose} data={props.data}/>
            <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={active ? classes.avatar : null}>
                        {props.data.isLocal ? "L" : "R"}
                    </Avatar>
                }
                action={
                        <Tooltip title="Activate / Inactivate local engine">
                            <Switch
                                checked={active}
                                onChange={handleActive}
                                color="primary"
                                name="activateEngine"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Tooltip>
                }
                title={props.data.name}
            />
            <Divider variant="middle" />
            <CardContent className={classes.box}>
                <div className={classes.root}>
                    <div className={classes.wrapper}>
                        <Fab
                            aria-label="save"
                            color={"primary"}
                            className={buttonClassname}
                            onClick={!connected ? handleButtonClick : null}
                            disabled={!active}
                        >
                            {connected ? <CheckIcon /> : <SettingsInputAntennaIcon />}
                        </Fab>
                        {connecting && <CircularProgress size={68} className={classes.fabProgress} />}
                    </div>
                    <div className={classes.wrapper}>
                        {!connected ? (
                            <Button
                                variant="contained"
                                className={buttonClassname}
                                disabled={connecting || !active}
                                onClick={handleButtonClick}
                            >
                                {active && error ? "Try again" : "Connect"}
                            </Button>
                        ) : <Typography disabled color={green[500]} className={classes.connected}>Connected</Typography>}
                    </div>
                </div>
            </CardContent>
            { active && error && (
                <Alert style={{margin: '0px 10px 10px 10px'}} severity="error">Error occurred!</Alert>
            )}
            { active && !error && connectionClosed && (
                <Alert style={{margin: '0px 10px 10px 10px'}} severity="warning">Connection was closed.</Alert>
            )}
            <Divider variant="middle" />
            <BottomNavigation
                showLabels
            >
                <BottomNavigationAction label="Info" icon={<InfoIcon color={active ? "action" : "disabled"}/> } />
                <BottomNavigationAction label="Logs" icon={<HistoryIcon color={active ? "action" : "disabled"} /> } />
                <BottomNavigationAction label="Settings" icon={<SettingsIcon color={active ? "action" : "disabled"} onClick={() => setShowSettings(true)}/> } />
            </BottomNavigation>
        </Card>
        </React.Fragment>
    );
};

Engine.propTypes = {
    data: PropTypes.object.isRequired
};

export default Engine;