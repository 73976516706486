import MenuAppBar from "../components/MenuAppBar";
import Engines from "../views/Engines";
import React from 'react';
import Devices from "./Devices";
import Toolbar from "@material-ui/core/Toolbar";

export default function Dashboard() {
    return (
        <React.Fragment>
            <MenuAppBar />
            <Toolbar />
            <Engines />
            <Devices />
        </React.Fragment>
    );
}
