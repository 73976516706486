import eventBus from "./eventBus";

class nabEngineClient {
    constructor(host, port, protocol) {
        this.url = (protocol ? protocol : "wss") + "://" + host + ":" + port;
        this.callBacks = {};
    }

    connect() {
        this.ws = new WebSocket(this.url);

        this.ws.onopen = (ev) => {
            this._onOpen(ev);
            this.onOpen(ev);
        }

        this.ws.onclose = (ev) => {
            this.onClose(ev);
            this._onClose(ev);
        }

        this.ws.onerror = (ev) => {
            this.onError(ev);
            this._onError(ev);
        }

        this.ws.onmessage = (msg) => this._onMessage(msg);
    }

    disconnect() {
        if (this.ws && this.ws.readyState !== WebSocket.CLOSED && this.ws.readyState !== WebSocket.CLOSING) {
            console.log('CLOSING')
            this.ws.close();
        }
    }

    send(data, cB) {
        // if (typeof cB == 'function') {
        //     const uid = this.genUID();
        //
        //     data._uid_ = uid;
        //     this.callBacks.uid = cB;
        // }

        const uid = this.genUID();
        data._uid_ = uid;

        this.ws.send(JSON.stringify(data));
    }

    onOpen() {}

    _onOpen() {
        this.registerEvents();
    }

    _onMessage(msg) {
        eventBus.publish("nabEngineGlobalMessageReceived", msg.data);

        const jsonData = JSON.parse(msg.data);

        if (jsonData.action) {
            switch (jsonData.action) {
                case "connected":
                    this.uid = jsonData.data.uid;
                    console.log(jsonData);
                    eventBus.publish("nabEngineConnected", {uid: this.uid});
                    break;
                case "getDevices":
                    eventBus.publish("nabEngineGetDevices", jsonData);
                    break;
                case "loadNabPackage":
                    eventBus.publish("nabEngineLoadNabPackage", jsonData);
                    break;
                case "captureScreen":
                    eventBus.publish("nabEngineCaptureScreen", jsonData);
                    break;
                case "startStory":
                    eventBus.publish("nabEngineStartStory", jsonData);
                    break;
                case "stopStory":
                    eventBus.publish("nabEngineStopStory", jsonData);
                    break;
                default:
                    console.log("NabEngineClient - Not supported action");
            }
        }

        // if (JSON.parse(msg.data)._uid_) {
        //     this.callBacks[JSON.parse(msg.data)._uid_](msg.data);
        //     delete this.callBacks[JSON.parse(msg.data)._uid_];
        // }
    }

    onClose() {}

    _onClose() {
        eventBus.publish("nabEngineConnectionClosed", {uid: this.uid});
        this.unRegisterEvents();
    }

    onError() {}

    _onError() {
        this.unRegisterEvents();
    }

    registerEvents() {
        this.evSub = eventBus.subscribe("nabEngineMessageSend", this.onNabEngineMessageSend);
    }

    unRegisterEvents() {
        if (this.evSub) {
            console.log("REMOVE EVENT: nabEngineMessageSend");
            this.evSub.unsubscribe();
            delete this.evSub;
        }
    }

    onNabEngineMessageSend = (data) => {
        console.log(data);

        if (!data.nabEngineUid || (data.nabEngineUid && data.nabEngineUid === this.uid)) {
            console.log("NAB - sending message: " + this.uid);
            this.send(data);
        }
    }

    genUID() {
        return Date.now().toString(36) + Math.random().toString(36).substring(2);
    }
}

export default nabEngineClient;