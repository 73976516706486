import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, Grid, Tooltip} from "@material-ui/core";

import React from "react";

import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles({
    card: {
        minWidth: 240
    },
    cardContent: {
        display: "block",
        height: '100%',
    },
    grid: {
        height: '100%',
        textAlign: "center"
    }
});

export default function EngineAdd() {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Grid container spacing={0} direction="row" alignItems="center" justify="center" className={classes.grid}>
                    <Grid item xs={12}>
                        <Tooltip title="Add new engine" enterDelay={500}>
                            <IconButton
                                color="default"
                            >
                                <AddIcon style={{ fontSize: 50 }}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}