import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';


const ConfirmDialog = (props) => {
    // const theme = useTheme();
    const fullScreen = false; //useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={() => props.onClose(false)}
                aria-labelledby="responsive-dialog-title"
            >
                {
                    props.title && (
                        <DialogTitle id="responsive-dialog-title">
                            {props.title}
                        </DialogTitle>
                    )
                }
                {
                    props.text && (
                        <DialogContent>
                            <DialogContentText>
                                {props.text}
                            </DialogContentText>
                        </DialogContent>
                    )
                }
                <DialogActions>
                    <Button onClick={() => props.onClose(true)} autoFocus>
                        Ok
                    </Button>
                    <Button autoFocus onClick={() => props.onClose(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    text: PropTypes.string
};

export default ConfirmDialog;