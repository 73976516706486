import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/core/styles";
import {DialogTitle} from "@material-ui/core";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const ModalDialog = (props) => {
    const classes = useStyles();
    const fullScreen = false; //useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={() => props.onClose(false)}
                aria-labelledby="responsive-dialog-title"
            >
                {
                    props.title && (
                        <DialogTitle disableTypography id="responsive-dialog-title" className={classes.root}>
                            <Typography variant="h6">{props.title}</Typography>
                            {props.onClose ? (
                                <IconButton aria-label="close" className={classes.closeButton} onClick={() => props.onClose(false)}>
                                    <CloseIcon />
                                </IconButton>
                            ) : null}
                        </DialogTitle>
                    )
                }

                        <DialogContent>
                            {
                                props.text && (
                                    <DialogContentText>
                                        {props.text}
                                    </DialogContentText>
                                )
                            }
                            <React.Fragment>
                                {props.children}
                            </React.Fragment>
                        </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(true)} autoFocus>
                        Ok
                    </Button>
                    <Button autoFocus onClick={() => props.onClose(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ModalDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    text: PropTypes.string
};

export default ModalDialog;