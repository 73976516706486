import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { blue } from '@material-ui/core/colors';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const StorySelection = (props) => {
    const classes = useStyles();
    const { onClose, open } = props;

    const stories = [
        {
            name: 'AdoptMe! - Gameplay',
            url: window.location.origin+"/nabs/AdoptMeGameplay.nab"
        }
    ];

    const handleClose = (v) => {
        onClose(v);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Select story</DialogTitle>
            <List>
                {stories.map((story) => (
                    <ListItem button onClick={() => handleClose(story)} key={story.name}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <ListAltIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={story.name} />
                    </ListItem>
                ))}

                {/*<ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>*/}
                {/*    <ListItemAvatar>*/}
                {/*        <Avatar>*/}
                {/*            <AddIcon />*/}
                {/*        </Avatar>*/}
                {/*    </ListItemAvatar>*/}
                {/*    <ListItemText primary="Add account" />*/}
                {/*</ListItem>*/}
            </List>
            <DialogActions>
                <Button autoFocus onClick={() => props.onClose()}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

StorySelection.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default StorySelection;
// function StorySelection() {
//     const [open, setOpen] = React.useState(false);
//     const [selectedValue, setSelectedValue] = React.useState(emails[1]);
//
//     const handleClickOpen = () => {
//         setOpen(true);
//     };
//
//     const handleClose = (value) => {
//         setOpen(false);
//         setSelectedValue(value);
//     };
//
//     return (
//         <div>
//             <Typography variant="subtitle1" component="div">
//                 Selected: {selectedValue}
//             </Typography>
//             <br />
//             <Button variant="outlined" onClick={handleClickOpen}>
//                 Open simple dialog
//             </Button>
//             <SimpleDialog
//                 selectedValue={selectedValue}
//                 open={open}
//                 onClose={handleClose}
//             />
//         </div>
//     );
// }
