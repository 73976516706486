import ModalDialog from "../components/ModalDialog";
import PropTypes from "prop-types";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@material-ui/core";

const EngineSettings = (props) => {
    const {data} = props;
    return (
        <ModalDialog open={props.open} onClose={props.onClose} title={"Settings"}>
            <TextField
                autoFocus
                required
                disabled={data.isLocal}
                margin="dense"
                id="name"
                label="Engine name"
                defaultValue={data.name}
                fullWidth
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="ip"
                label="IP / Host"
                defaultValue={data.ip}
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="port"
                label="Port"
                defaultValue={data.port}
            />
            <FormControl component="fieldset">
                <FormLabel component="legend">Protocol</FormLabel>
                <RadioGroup aria-label="protocol" name="protocol" value={data.protocol} onChange={null}>
                    <FormControlLabel value="ws" control={<Radio />} label="WebSocket (ws)" />
                    <FormControlLabel value="wss" control={<Radio />} label="WebSocket Secure (wss)" />
                </RadioGroup>
            </FormControl>
        </ModalDialog>
    )
}

EngineSettings.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default EngineSettings;