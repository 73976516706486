import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dashboard from "./views/Dashboard";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";
import {ThemeProvider} from "@material-ui/core/styles";
import {createTheme, useMediaQuery} from "@material-ui/core";

export default function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Dashboard />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}