import {makeStyles} from "@material-ui/core/styles";
import React, {useCallback, useEffect, useState} from 'react';
import {
    Avatar, Box, Card,
    CardContent,
    CardHeader, CircularProgress,
    Container,
    Divider, LinearProgress,
    Tooltip
} from "@material-ui/core";
import DevicesIcon from '@material-ui/icons/Devices';
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import {blueGrey} from "@material-ui/core/colors";
import eventBus from "../libs/eventBus";
import Typography from "@material-ui/core/Typography";
import Device from "../components/Device";


const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        width: '100%',
        maxWidth: "unset"
    },
    containerInside: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(2),
        },
        position: "relative",
        maxWidth: "unset"
    },
    avatar: {
        backgroundColor: blueGrey[500],
    },
    notFound: {
        width: '100%'
    },
    loaderBox: {
        width: '100%',
        height: '100%',
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, .5)",
        top: ( (-1) * parseInt(theme.spacing(2))),
        left: ( (-1) * parseInt(theme.spacing(2))),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none"
    },
    linearProgress: {
        position: "relative"
    }
}));

export default function Devices() {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const nabEngineConnectionClosed = useCallback((nabEngineUid) => {
        setData(Object.keys(data).filter(key => key !== nabEngineUid));
    },[data])

    const nabEngineConnected = useCallback((nabEngineUid) => {
        getDevices(nabEngineUid);
    }, [])

    const nabEngineGetDevices = useCallback((d) => {
        // to make it easier put nabEngineUid to each device data
        if (d.data && d.data.length > 0) {
            setData({...data, ...{ [d['nadEngineUid']] : d.data }});
        } else {
            d && setData(Object.keys(data).filter(key => key !== d['nadEngineUid']));
        }

        setLoading(false);
    }, [data])

    useEffect(() => {
        const nabEngineGetDevicesEvent = eventBus.subscribe("nabEngineGetDevices", (d) => {
            nabEngineGetDevices(d);
        });

        const nabEngineConnectionClosedEvent = eventBus.subscribe("nabEngineConnectionClosed", (d) => {
            nabEngineConnectionClosed(d.uid);
        });

        const nabEngineConnectedEvent = eventBus.subscribe("nabEngineConnected", (d) => {
            console.log(d)
            nabEngineConnected(d.uid);
        });

        // Specify how to clean up after this effect:
        return () => {
            nabEngineGetDevicesEvent.unsubscribe();
            nabEngineConnectionClosedEvent.unsubscribe();
            nabEngineConnectedEvent.unsubscribe();
        };
    },[nabEngineGetDevices, nabEngineConnectionClosed, nabEngineConnected]);

    const getDevices = (nabEngineUid) => {
        const d = { "action":"getDevices" };

        if (typeof nabEngineUid == 'string') {
            d.nabEngineUid = nabEngineUid;
        }

        if (eventBus.publish("nabEngineMessageSend", d)) {
            // if subscription to event exists
            setLoading(true);
        } else {
            // no NAB engines connected
            setData({});
        }
    }

    return (
        <React.Fragment>
            <Container className={classes.container}>
                <Card >
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                                <DevicesIcon />
                            </Avatar>
                        }
                        action={
                            <React.Fragment>
                                <Tooltip title="Refresh devices list">
                                    <IconButton aria-label="settings" onClick={getDevices} color={"primary"}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Want to find out more about 'Devices' section?">
                                    <IconButton aria-label="settings">
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </React.Fragment>
                        }
                        title="Devices"
                        subheader="List of available devices"
                    />
                    { loading && (
                        <LinearProgress className={classes.linearProgress}/>
                    ) }
                    <Divider variant="middle" />
                    <CardContent>
                        <Container className={classes.containerInside}>
                            { loading && (
                                <Box
                                    className={classes.loaderBox}
                                     zIndex="modal">
                                    <CircularProgress disableShrink />
                                </Box>
                            )}
                            {
                                Object.values(data).length > 0 ? (
                                    Object.values(data).map((d, i) => (
                                        d && d.map && d.map((dev, i) => (
                                            <Device key={i} data={dev} getDevices={getDevices} nabEngineUid={dev['nadEngineUid']}/>
                                        ))
                                    ))
                                )
                                 : (
                                    <Typography variant="subtitle1" align={'center'} color={"textSecondary"} className={classes.notFound}>
                                        No devices has been found
                                    </Typography>
                                )
                            }
                        </Container>
                    </CardContent>
                </Card>
            </Container>
        </React.Fragment>
    );
}

