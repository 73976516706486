import {makeStyles} from "@material-ui/core/styles";
import React from 'react';
import {Avatar, Card, CardContent, CardHeader, Container, Divider, Tooltip} from "@material-ui/core";
import MemoryIcon from '@material-ui/icons/Memory';
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Engine from "../components/Engine";
import EngineAdd from "../components/EngineAdd";
import {teal} from "@material-ui/core/colors";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        width: '100%',
        maxWidth: "unset"
    },
    containerInside: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(2),
        },
        maxWidth: "unset"
    },
    avatar: {
        backgroundColor: teal[500],
    }
}));

const urlParams = new URLSearchParams(window.location.search);

export default function Engines() {
    const classes = useStyles();
    const urlData = urlParams.has("data") ? JSON.parse(atob(urlParams.get("data"))) : {};
    //const urlData = urlParams.has("data") ? JSON.parse(atob(urlParams.get("data"))) : {"ip":"10.0.0.34", "port":"8989", "protocol": "ws"};

    return (
        <React.Fragment>
            <Container className={classes.container}>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                                <MemoryIcon />
                            </Avatar>
                        }
                        action={
                            <React.Fragment>
                                <Tooltip title="Download latest NAB engine">
                                    <IconButton aria-label="settings" color={"primary"}>
                                        <CloudDownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Want to find out more about 'NAB Engines' section?">
                                    <IconButton aria-label="settings" >
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </React.Fragment>
                        }
                        title="NAB Engines"
                        subheader="List of local and remote engines"
                    />
                    <Divider variant="middle" />
                    <CardContent>
                        <Container className={classes.containerInside}>
                            <Engine data={{...{name: "Local engine", isLocal: true, ip: "127.0.0.1", port: "8989", protocol: "ws"}, ...urlData}}/>
                            <Engine data={{name: "Raspberry Pi 4", isLocal: false, ip:"192.168.1.132", port:"8989", protocol: "ws"}}/>
                            <EngineAdd />
                        </Container>
                    </CardContent>
                </Card>
            </Container>
        </React.Fragment>
    );
}