import {makeStyles} from "@material-ui/core/styles";
import {
    Alert,
    Avatar, BottomNavigation, BottomNavigationAction, Card,
    CardContent,
    CardHeader, CircularProgress, Divider, LinearProgress, Tooltip, Typography
} from "@material-ui/core";
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from "react";
import {blueGrey, green, orange} from "@material-ui/core/colors";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import eventBus from "../libs/eventBus";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SettingsIcon from "@material-ui/icons/Settings";
import StopCircleOutlinedIcon from '@material-ui/icons/StopCircleOutlined';
import ConfirmDialog from "./ConfirmDialog";
import StorySelection from "../views/StorySelection";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import RefreshIcon from "@material-ui/icons/Refresh";


const useStyles = makeStyles((theme) => ({
    card: {
        // minWidth: 300
    },
    avatar: {
        backgroundColor: blueGrey[800],
    },
    box: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap"
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonUpload: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    prevImg: {
        maxHeight: "250px",
        maxWidth: "250px"
    },
    notFound: {
        padding: "25px"
    },
    notAuthorizedMsg: {
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // height: "100%",
        textAlign: "center",
        padding: theme.spacing(1)
    }
}));

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const Device = (props) => {
    const classes = useStyles();

    const captureScreenInterval = 30; // in seconds

    const [screenPng, setScreenPng] = useState();
    const [captureProgress, setCaptureProgress] = useState(0);
    const [storyRunning, setStoryRunning] = useState(props.data['executionStatus'] !== null);
    const [storyLoading, setStoryLoading] = useState(false);
    const [storyLoadingStatus, setStoryLoadingStatus] = useState();
    const [selectedStory, setSelectedStory] = useState(props.data['executionStatus'] !== null ? props.data['executionStatus'] : {name: ""});
    const [selectStoryConfirmOpen, setSelectStoryConfirmOpen] = useState(false);
    const [selectStoryOpen, setSelectStoryOpen] = useState(false);

    const deviceName = props.data.authorized ? capitalize(props.data.props['ro.product.manufacturer']) + " " + props.data.props['ro.product.model'] : "Unauthorized";

    const captureScreen = useCallback(() => {
        props.data.authorized && eventBus.publish("nabEngineMessageSend", {
            nabEngineUid: props.nabEngineUid,
            action: "captureScreen",
            data: {
                devUid: props.data.uid
            }
        });
    }, [props.data.uid, props.data.authorized, props.nabEngineUid]);

    useEffect(() => {
        console.log("DEVice - useEffect triggered")
        let captureProgressInterval;

        const evLoadNabPackage = eventBus.subscribe("nabEngineLoadNabPackage", (d) => {
            console.log(d);
            if (d && d['devUid'] === props.data.uid) {
                setStoryLoading(false);
                setStoryLoadingStatus(d.status);
                console.log(d.status);
            }
        });

        const evCaptureScreen = eventBus.subscribe("nabEngineCaptureScreen", (d) => {
            captureProgressInterval && clearInterval(captureProgressInterval);
            captureProgressInterval = startCaptureProgress();

            if (d && d.data) {
                d['devUid'] === props.data.uid && setScreenPng(d.data['png']);
            } else {
                //props.getDevices(props.nabEngineUid);
            }
        });

        const evStartStory = eventBus.subscribe("nabEngineStartStory", (d) => {
            if (d && d['devUid'] === props.data.uid) {
                setStoryRunning(true);
                setStoryLoading(false);
            }
        });

        const evStopStory = eventBus.subscribe("nabEngineStopStory", (d) => {
            if (d && d['devUid'] === props.data.uid) {
                setStoryRunning(false);
                setStoryLoading(false);
            }
        });

        captureScreen();
        const captureInterval = setInterval(captureScreen, captureScreenInterval * 1000);

        // Specify how to clean up after this effect:
        return () => {
            console.log("DEVice - useEffect cleaned")
            evLoadNabPackage.unsubscribe();
            evCaptureScreen.unsubscribe();
            evStartStory.unsubscribe();
            evStopStory.unsubscribe();
            captureProgressInterval && clearInterval(captureProgressInterval);
            clearInterval(captureInterval);
        };
    }, [captureScreen, props.data.uid]);

    const selectStory = () => {
        storyRunning ? setSelectStoryConfirmOpen(true) : setSelectStoryOpen(true);
    }

    const handleStorySelection = (story) => {
        setSelectStoryOpen(false);
        story && loadNabPackage(story);
    }

    const handleStorySelectionConfirm = (isOk) => {
        setSelectStoryConfirmOpen(false);

        if (isOk) {
            stopStory();
            setSelectStoryOpen(true);
        }
    }

    const loadNabPackage = (story) => {
        //const url = "http://localhost:3000/nabs/test.nab";
        //setSelectedStoryName("MyFirstNabPackage");
        setSelectedStory(story);
        setStoryLoading(true);

        eventBus.publish("nabEngineMessageSend", {
            nabEngineUid: props.nabEngineUid,
            action: "loadNabPackage",
            data: {
                devUid: props.data.uid,
                location: story.url
            }
        });
    }

    const startCaptureProgress = () => {
        const steps = 50;
        let i = 0;

        const captureProgressInterval = setInterval(() => {
            i = i + (100 / steps);
            if (captureProgressInterval && i >= 100)  {
                clearInterval(captureProgressInterval);
                setCaptureProgress(0);
            } else {
                setCaptureProgress(i);
            }
        }, captureScreenInterval * 1000 / steps);

        return captureProgressInterval;
    }

    const startStory = () => {
        eventBus.publish("nabEngineMessageSend", {
            nabEngineUid: props.nabEngineUid,
            action: "startStory",
            data: {
                device: {
                    "type": "Android",
                    "identifier": props.data.uid
                },
                story: selectedStory
            }
        });
        setStoryLoading(true);
    }

    const stopStory = () => {
        eventBus.publish("nabEngineMessageSend", {
            nabEngineUid: props.nabEngineUid,
            action: "stopStory",
            data: {
                devUid: props.data.uid
            }
        });
        setStoryLoading(true);
    }

    return (
        <React.Fragment>
            <ConfirmDialog open={selectStoryConfirmOpen} onClose={handleStorySelectionConfirm} title={"Are you sure?"}  text={"Story "+selectedStory.name+" is currently running on device "+deviceName+". If you wish to continue with story selection current story execution will be stopped."} />
            <StorySelection open={selectStoryOpen} onClose={handleStorySelection}/>
            <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        <PhoneAndroidIcon />
                    </Avatar>
                }
                action={
                        <React.Fragment>
                            {
                                props.data.authorized ?
                                    (
                                        <Tooltip title="Settings">
                                            <IconButton aria-label="Settings">
                                                <SettingsIcon />
                                                {/*TODO: lock screen disable etc.., avoid screen blank etc..., control volume, control device screen brightness, identify by screen brightness blinking, put your notes / description*/}
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="See 'How to authorize device' guide">
                                            <IconButton aria-label="Settings">
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                            }
                        </React.Fragment>
                }
                title={
                    deviceName
                }
                subheader={
                    <Typography variant="caption" color="textSecondary">
                        {props.data.uid}
                    </Typography>
                }
            />
            <Divider variant="middle" />
                {
                    props.data.authorized ? (
                        <React.Fragment>
                            <CardContent className={classes.box}>
                                <div>
                                    { screenPng ? (
                                        <React.Fragment>
                                            <img className={classes.prevImg} src={screenPng} alt={"screenPreview"}/>
                                            <LinearProgress variant="determinate" value={captureProgress} />
                                        </React.Fragment>
                                    ) : (
                                        <CircularProgress />
                                    ) }
                                </div>
                            </CardContent>
                        <Divider variant="middle" />
                    <Typography variant="subtitle2" align={'center'} color={"textSecondary"} className={classes.notFound}>
                        { selectedStory.name ? (
                            storyLoadingStatus === "error" ? (
                                <Alert style={{margin: '0px 10px 10px 10px'}} severity="error">Could not load story!<br/>Try again ...</Alert>
                            ) : (
                                    <React.Fragment>
                                    <b>{selectedStory.name}</b>
                                    <br/>
                                    <span style={{color: storyRunning ? green[600] : orange[600]}}>
                                    { storyRunning ? "running" : "stopped" }
                                        </span>
                                        </React.Fragment>
                                        )
                                        ) : (
                                        "No story selected"
                                        )
                                    }
                    </Typography>
                    <Divider variant="middle" />
                        {
                            storyLoading ? (
                                <React.Fragment>
                                <Typography variant="subtitle2" align={'center'} color={"textSecondary"} className={classes.notFound}>
                                Loading story ...
                                </Typography>
                                <LinearProgress />
                                </React.Fragment>
                                ) : (
                                    <BottomNavigation showLabels>
                                        {
                                            storyRunning ?
                                                (
                                                    <BottomNavigationAction label="Stop story" icon={<StopCircleOutlinedIcon color={"action"}/>} onClick={stopStory} />
                                                ) : (
                                                        <BottomNavigationAction label="Start story" disabled={!storyLoadingStatus || storyLoadingStatus === "error"} icon={<PlayCircleOutlineIcon color={storyLoadingStatus === "success" ? "action" : "disabled"}/> } onClick={startStory} />
                                                    )
                                        }
                                        <BottomNavigationAction label="Select story" icon={<SearchIcon color={"action"}/> } onClick={selectStory} />
                                        {/*<BottomNavigationAction label="Logs" icon={<HistoryIcon color={"action"} /> } />*/}
                                    </BottomNavigation>
                                )
                        }
                        </React.Fragment>
                    ) : (
                        // <CardContent className={classes.box}>
                        <React.Fragment>
                            <div className={classes.notAuthorizedMsg}>
                                <ErrorOutlineIcon color="disabled" sx={{ fontSize: 150 }}/>
                                <br/>
                                <Typography variant="subtitle1" align={'center'} color={"textSecondary"}>
                                    Check for a confirmation<br/>dialog on your device
                                </Typography>
                                <br/>
                                <Tooltip title="Refresh devices list">
                                    <IconButton aria-label="settings" onClick={() => props.getDevices(props.nabEngineUid)} color={"primary"}>
                                        <RefreshIcon fontSize="large"/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </React.Fragment>
                        // </CardContent>
                    )
                }
        </Card>
        </React.Fragment>
    );
};

Device.propTypes = {
    nabEngineUid: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    getDevices: PropTypes.func.isRequired
};

export default Device;